import React from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

const images = [
  "../assets/lacrosse-1.jpg",
  "../assets/lacrosse-2.jpg",
  "../assets/lacrosse-3.jpg",
  "../assets/lacrosse-4.jpg",
  "../assets/lacrosse-5.jpg",
  "../assets/football-1.jpeg",
  "../assets/football-2.jpeg",
  "../assets/taekwando-1.jpeg",
  "../assets/basketball-1.jpeg",
  "../assets/soccer-1.jpg",
  "../assets/soccer-3.jpg",
  "../assets/soccer-2.jpg",
  "../assets/soccer-4.jpg",
  "../assets/soccer-5.jpg",
  "../assets/soccer-6.jpg",
  "../assets/soccer-7.jpg",
  "../assets/track-1.jpg",
  "../assets/track-2.jpg",
  "../assets/track-3.jpg",
  "../assets/track-4.jpg",
  "../assets/track-5.jpg",
  "../assets/cc-1.jpg",
  "../assets/cc-2.jpg",
]

function Sports() {
  return (
    <div className="gallery-bg">
      <div className="container"></div>
    <div className="container">
      <div className="row"  >
        <h1>Sports Photography</h1>
        <p>Hey, Fort Wayne!  I'm a dad of two active kids who LOVE playing sports.  I want to start sharing my love of sports photography with the rest of 
          our community by offering sports photography to Fort Wayne and surrounding areas.  
          Fort Wayne parents, let us come out to your child's sporting event and capture some action shots of your child or their team. 
          <br/>
          <br />
          Our services start at $50 per game,
          this will give you our full attention for the event as well as lightly edited photos ready to share on social media or to print.
          <br/>
          <br/>
           <a href="/contact">Contact me</a> to book your event! 
        </p>
        </div>
        <div className="row">
        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}} style={{width: "100%"}}>
        <Masonry columnsCount={3} gutter="10px">
          {images.map((image, i) => (
            <img
              key={i}
              src={image}
              alt=""
              style={{width: "100%", display: "block"}}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
    </div>
    </div>
  );
}

export default Sports;
