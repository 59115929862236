import React from "react";

function Home() {
  return (
    <div className="intro-bg">
  <div className="overlay">
    <div className="container">
      <div className="row">
        <div className="col-md-9 col-sm-9 col-xs-9 intro-content justify-content-start">

        <h1>Hi! I'm Jared Haagen.</h1>
          <p>I am a freelance sports photographer and web developer at my company called Sites &amp; Scenes.
            <br/> I live in Fort Wayne, Indiana and work at <a href="http://www.sweetwater.com">Sweetwater Sound</a>.</p>

          <p>When I'm not driving my 2 kids to their sports, I'm either riding bikes, detailing cars, or finding yet another hobby.</p>
        </div>
      </div>
    </div>
  </div>
</div>
  );
}

export default Home;
