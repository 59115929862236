import React from "react";

function Photo() {
  return (
    <div className="photo-page">
    <div className="photo-bg">
    <div className="overlay">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-sm-9 col-xs-9 content justify-content-start">
          <h1>Photography</h1>
            <p>I've spent years with a camera in my hand. These days I focus on sports photography with the occasional real estate or portrait shoot thrown in there for fun!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div className="photo-bg second">
    <div className="overlay">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-sm-9 col-xs-9 content justify-content-start">
            <p>From kids sports, to the pro games shooting sports is always a challenge and a fun day! Want some amazing memories of your child (at any age) playing their favorite sport. Contact me!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="photo-bg third">
    <div className="overlay">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-sm-9 col-xs-9 content justify-content-start">
            <p>I also have many years in the Real Estate photo business. Hundreds of homes in the Fort Wayne area have been sold with my pictures attached, I can't take all the credit, but I feel my photos helped! I'm happy to help you sell your home!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="photo-bg fourth">
    <div className="overlay">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-sm-9 col-xs-9 content justify-content-start">
            <p>Or maybe you just have an amazing backyard, or property you just want a night time photo or even an overhead image from a drone, give me a call. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  );
}

export default Photo;
