import React from "react";

function Contact() {
  return (
    <div className="contact-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-sm-9 col-xs-9 content">
            <h1>Contact me!</h1>
        
            <p>I could add a form here to email me, but instead I'm going to leave some links to more modern contact methods... </p>
            <p>
            <a href="https://github.com/jhaagen">Github</a>
              <br/>
              <a href="https://codepen.io/jhaagen">Code Pen</a>
              <br/>
              <a href="https://www.linkedin.com/in/jaredhaagen/">LinkedIn</a>
              <br/>
              <a href="https://www.google.com/search?q=%22jared+haagen%22">Google</a>
            </p>
            <p>If you can't find me here, maybe our paths will cross by chance in the future!</p>
          </div>
        </div>
    </div>
  </div>
  );
}

export default Contact;
